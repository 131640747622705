<template>
    <div class="home">
        <div class="header clearfix">
            <div class="desktop-top-header-area">
                <InfoMenu />
                <BrandMenu />
                <ServiceMenu />
                <NavMenu />
            </div>
            <div class="mobile-top-header-area">
                <InfoMenu />
                <BrandMenuMobile />
            </div>
        </div>
        <div v-if="isPageLoad" class="preLoader">
            <img class="img-responsive" :src="'/eurasia/img/logo/coolbuy-fav.png'" alt="logo">
            <img class="img-responsive" :src="'/eurasia/img/logo/loading.gif'" alt="logo">
        </div>
        <router-view />

        <FooterTopDescription />
        <FooterSocial />
        <FooterMenu />

    </div>

</template>

<script>
// @ is an alias to /src
import InfoMenu from "@/views/header/InfoMenu";
import BrandMenu from "@/views/header/BrandMenu";
import BrandMenuMobile from "@/views/header/BrandMenuMobile";
import ServiceMenu from "@/views/header/ServiceMenu";
import NavMenu from "@/views/header/NavMenu";
import FooterMenu from "@/views/footer/FooterMenu";
import FooterTopDescription from "@/views/footer/FooterTopDescription";
import FooterSocial from "@/views/footer/FooterSocial";
!function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
}(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js');
// window.fbq('init', '777792696884238');
window.fbq('init', '1996185650807019');
window.fbq('track', 'PageView', { value: 0, currency: 'BDT' });
export default {
    name: 'MainPage',
    /*data() {
        return {
            isPageLoad: true
        };
    },*/
    components: {
        InfoMenu,
        BrandMenu,
        BrandMenuMobile,
        ServiceMenu,
        NavMenu,
        FooterMenu,
        FooterTopDescription,
        FooterSocial
    },
    mounted() {
        this.$store.dispatch("getHome");
        this.$store.dispatch("getCategories");
        this.$store.dispatch("getBrands");
        this.$store.dispatch("getTopSelling");
        this.$store.dispatch("clearSiteUrl");
        this.$store.dispatch("getsaleNow");
        this.$store.dispatch("getSiteSettings");
        /*document.onreadystatechange = () =>{
            if(document.readyState === "complete"){
                this.isPageLoad = false;
            }
        }*/
    },
}
</script>
<style>
.preLoader {
    position: absolute;
    background: #fff;
    height: 100vh;
    width: 100%;
    z-index: 999;
    top: 0;
    text-align: center;
}

.preLoader img {
    height: 100px;
    width: auto;
    margin: 0 auto;
}
</style>